import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './layout/content.layout/content.layout.component';
import { FullpageLayoutComponent } from './layout/fullpage.layout/fullpage.layout.component';
import { AuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { AppLoaderComponent } from './layout/app-loader/app-loader.component';
import { isNewUserGuard } from './modules/dashboard/guards/is-new-user.guard';
import { IsNotDeletedGuard } from './modules/auth/guards/is-not-deleted.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth']);
const redirectAuthenticated = () => redirectLoggedInTo(['/app']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: FullpageLayoutComponent,
    children: [
      {
      path: '',
      loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: 'app',
    component: AppLoaderComponent,
    canActivate: [AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
    children: [
      {
      path: '',
      loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  {
    path: 'contracting',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/contracting/contracting.module').then(m => m.ContractingModule)
      }
    ]
  },
  {
    path: 'error',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled', scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

