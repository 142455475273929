import { Injectable, computed, inject, signal } from '@angular/core';
import { Auth,  getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AuthResponse } from '../interfaces/auth.response.interface';
import { LuxorUser } from '../interfaces/luxor.user.interface';
import { doc, Firestore, getDoc, onSnapshot } from  "@angular/fire/firestore";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private firestore: Firestore = inject(Firestore);
  private auth: Auth = inject(Auth);

  private _currentUser = signal<LuxorUser|null>(null);
  public currentUser = computed( () => this._currentUser() );

  constructor() {
    this.checkUser().subscribe();
  }

  async login(email:string, password:string):Promise<Observable<AuthResponse>> {

    let authResponse:AuthResponse = {
      uid : '',
      error: ''
    }

    await signInWithEmailAndPassword(this.auth, email, password)
      .then(res => {
        let uid = res.user.uid;
        authResponse.uid = uid;
        if (uid !== null) return of(authResponse);
        authResponse.error = 'No he podido recuperar el uid';
        return of(authResponse);
      })
      .catch(e => {
        authResponse.error = e.message;
        return e.message; 
      })
      return of(authResponse);
  }

  async getAgent(uid: string): Promise<boolean> {
    const docRef = doc(this.firestore, "agents", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      this._currentUser.set(docSnap.data() as LuxorUser);
      return true;
    }
    return false;
  }
  
  checkUser():Observable<boolean> {
    if (this._currentUser() === null ) {
      const user = this.auth.currentUser;
      try {
        onSnapshot(doc(this.firestore, "users", user!.uid), (doc) => {
          this._currentUser.set(doc.data() as LuxorUser);
          return of(true);
      });
      } catch (error) {
        return of(false);
      }
    }
    return of(true);
  }

  sendResetEmail(email:string):void {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
  .then(() => {
  })
  .catch((error) => {
    const errorMessage = error.message;
  });
  }


  logout() {
    this._currentUser.set(null);
    return signOut(this.auth);
  }


}
