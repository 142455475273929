import { Injectable } from '@angular/core';
import { DocumentData, Firestore, QuerySnapshot, collection, doc, getDocs, onSnapshot, query, setDoc, updateDoc } from '@angular/fire/firestore';
import { Cta } from '../interfaces/cta.interface';
import { Observable, from, of } from 'rxjs';
import { ref } from 'firebase/storage';
import { Storage, getDownloadURL, uploadBytes } from '@angular/fire/storage';
import { Newsletter } from '../interfaces/newsletter.interface';
import { ContactForm } from '../interfaces/contact-form.interface';
import { getDoc, limit, orderBy, startAfter, where } from 'firebase/firestore';
import { LuxorUser } from '../interfaces';
import { Store } from '../interfaces/store.interface';
@Injectable({
  providedIn: 'root'
})
export class CtaService {

  constructor(private db:Firestore, private storage:Storage) { }

  async postCta(cta:Cta) {
    try {
     await setDoc(doc(this.db, 'cta', cta.uid), cta);
     return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async findCtaById(uid:string):Promise<Cta | undefined> { 
    const docRef = doc(this.db, 'cta', uid);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists) return;
    return docSnap.data() as Cta;
  }

  realTimeAllCta():Observable<Cta[]> {
    const result:Cta[] = [];
    const q = query(collection(this.db, "cta"));
    onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
        result.push(doc.data() as Cta);
      });
    });
    return of(result);
  }

  async retrieveManagerCta(manager:LuxorUser):Promise<Cta[]> {
    const result:Cta[] = [];
    const employees:string[] = await this.retrieveEmployeesIds(manager);
    employees.push('unknown');
    const q = query(collection(this.db, "cta"), where("agent", "==", manager.uid), where("isDeleted", "!=" , true));
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      const cta:Cta = doc.data() as Cta;
      result.push(cta);
    }
    const secondQuery = query(collection(this.db, "cta"), where("agent", "in", employees))
    const secondarySnapshot = await getDocs(secondQuery);
    for (let doc of secondarySnapshot.docs) {
      const cta:Cta = doc.data() as Cta;
      result.push(cta);
    }
    return result;
  }

  async retrieveUserCtas(user:LuxorUser):Promise<Cta[]> {
    const result:Cta[] = [];
    const queryParams:string[] = ['unknown', user.uid];
    const q = query(collection(this.db, "cta"), where("agent", "in", queryParams), where("isDeleted", "!=" , true));
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      const cta:Cta = doc.data() as Cta;
      result.push(cta);
    }
    return result;
  }

  async deleteCta(uid:string):Promise<boolean> {
    try {
      const docRef = doc(this.db, 'cta', uid);
      await updateDoc(docRef, {
        isDeleted:  true
      })
      return true;
    } catch (error) {
      return false;
    }
  }

  async findAllCta():Promise<Cta[]> {
    const ctas:Cta[] = [];
    const q = query(collection(this.db, "cta"), where("isDeleted", "!=" , true), orderBy("createdAt", "desc"));
    const documentSnapshots  = await getDocs(q);
    for (const doc of documentSnapshots.docs) { 
      const cta:Cta = doc.data() as Cta;
      ctas.push(cta);
    }
    return ctas;
   }

   async retrieveEmployeesIds(manager:LuxorUser):Promise<string[]> { 
    const managerEmployees:string[] =  [];
    const docRef = doc(this.db, "stores", manager.current_store);
    const docSnap = await getDoc(docRef);
    const store:Store = docSnap.data() as Store;
    for (let employee of store.employees!) {
      managerEmployees.push(employee);
    }
    return managerEmployees;
  }


  async fixAppointment(cta:Cta, date:Date, uid:string):Promise<boolean> {
    const docRef = doc(this.db, 'cta', cta.uid);
    try {
      await updateDoc(docRef, {
        isAttended: true,
        agent: uid,
        appointmentDate: date.getTime(),
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async isCtaInOp(uid:string):Promise<boolean> {
    const opRef = collection(this.db, 'operations');
    const q = query(opRef, where('cta' , '==' , uid ));
    const snaps = await getDocs(q);
    if (snaps.empty) return false;
    return true;
  }




  async assignOpToCta(cta:string, opId:string):Promise<boolean> { 
    const docRef = doc(this.db, 'cta', cta);
    try {
      await updateDoc(docRef, {opId});
      return true;
    } catch (error) {
      return false;
    }
  }

  async updateCta(cta:Cta):Promise<boolean> { 
    const docRef = doc(this.db, 'cta', cta.uid);
    try {
      await updateDoc(docRef, {...cta});
      return true;
    } catch (error) {
      return false;
    }
  }

  async attendCta(uid:string, value:boolean):Promise<boolean> {
    try {
      const docRef = doc(this.db, 'cta', uid);
      await updateDoc(docRef, {
        isAttended:  value
      })
      return true;
    } catch (error) {
      return false;
    }
  }

  async uploadFormPicture(file:File):Promise<string> { 
    let downloadUrl = '';
    const filePath = '/forms/' + file.name;
    const fileRef = ref(this.storage, filePath);
    await uploadBytes(fileRef, file);
    await getDownloadURL(fileRef).then((downloadUrl) => downloadUrl = downloadUrl);
    return downloadUrl;
  }

  async postNewsletterRef(newsletter:Newsletter):Promise<boolean> { 
    try {
      await setDoc(doc(this.db, 'newsletter', newsletter.uid), newsletter);
      return true;
    } catch (error) {
      return false;
    }
  }

  async postContactForm(contactForm:ContactForm):Promise<boolean> { 
    try {
      await setDoc(doc(this.db, 'contacts', contactForm.uid), contactForm);
      return true;
    } catch (error) {
      return false;
    }
  }


}
