import { ViewportScroller } from '@angular/common';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  constructor(private viewportScroller: ViewportScroller) {}
  
  public init: boolean;
  private topPosToStartShowing:number = 100;

  
  @HostListener('window:scroll')
  checkScroll():void {
  const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.init = false;
    } else {
      this.init = true;
    }
  }

}
