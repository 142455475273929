import { Injectable, inject } from '@angular/core';
import {
  Firestore,
  arrayUnion,
  collection,
  doc,
  docData,
  getDoc,
  getDocs,
  setDoc,
  updateDoc
} from '@angular/fire/firestore';
import { Client, DNI } from '../interfaces/client.interface';
import { Attachment } from '../interfaces';
import {
  Storage,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { query, where } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  private db = inject(Firestore);
  private storage = inject(Storage);

  async retrieveClients(): Promise<Client[]> {
    const respClients: Client[] = [];
    const q = query(collection(this.db, 'clients'), where("isDeleted", "!=" , true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      respClients.push(doc.data() as Client);
    });
    return respClients;
  }

  async createClient(client: Client) {
    const docRef = doc(this.db, 'clients', client.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return await updateDoc(docRef, { ...client });
    } else {
      const now = Date.now();
      const newClient: Client = { ...client, createdAt: now, isDeleted: false};
      return await setDoc(doc(this.db, 'clients', client.uid), newClient);
    }
  }

  async retrivieClientById(uid: string): Promise<Client | undefined> {
    const docRef = doc(this.db, 'clients', uid);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      return undefined;
    }
    return docSnap.data() as Client;
  }

  async postImages(files: File[]): Promise<Attachment[]> {
    const attachments: Attachment[] = [];
    for (let file of files) {
      let downloadUrl: string = '';
      const filePath = '/dni/' + file.name;
      const fileRef = ref(this.storage, filePath);
      await uploadBytesResumable(fileRef, file);
      const publicUrl = await getDownloadURL(fileRef).then(
        (url) => (downloadUrl = url)
      );
      let attachment: Attachment = {
        name: file.name,
        type: file.type,
        size: file.size,
        downloadUrl: publicUrl,
      };
      attachments.push(attachment);
    }
    return attachments;
  }

  async postClient(client: Client, opId?: string): Promise<boolean> {
    const clientExists = await this.userExists(client.uid);

    if (clientExists && opId) {
      await this.updateClientOperations(client, opId);
      console.log('He actualizado al cliente');
      return true;
    }
    console.log('Voy a publicar al cliente');
    try {
      await setDoc(doc(this.db, 'clients', client.uid), client);
    } catch (error) {
      console.log(error);
    }
    console.log('He publicado al cliente');
    return true;
  }

  async userExists(uid: string): Promise<boolean> {
    const docRef = doc(this.db, 'clients', uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  }

  async updateClientOperations(client: Client, opId: string): Promise<void> {
    const clientRef = doc(this.db, 'clients', client.uid);
    await updateDoc(clientRef, {
      operations: arrayUnion(opId),
    });
  }

  async updateClientDni(dni: DNI, client: Client): Promise<boolean> {
    const clientRef = doc(this.db, 'clients', client.uid);
    try {
      await updateDoc(clientRef, {
        dni: dni,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async updateClient(client: Client): Promise<boolean> {
    try {
      const clientRef = doc(this.db, 'clients', client.uid);
      await updateDoc(clientRef, {
        ...client,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async updateClientPartial(uid: string, field: Partial<Client>): Promise<boolean> {
    const clientRef = doc(this.db, 'clients', uid);
    try {
      await updateDoc(clientRef, field);
      return true;
    } catch {
      return false;
    }
  }

  transformClientExpDate(client: Client): Date {
    const string = client.dni?.exp.toString();
    const date = new Date(string?.replaceAll('__Timestamp__', '')!);
    return date;
  }

  async updateClientObservations(
    client: Client,
    observations: string
  ): Promise<boolean> {
    const clientRef = doc(this.db, 'clients', client.uid);
    try {
      await updateDoc(clientRef, {
        observations,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
}
