import { Component, OnInit, computed, inject } from '@angular/core';
import { adminMenu } from 'src/environments/variables';
import { LuxorMenuItem } from 'src/app/shared/interfaces/menuItem.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import {  Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LuxNotification } from 'src/app/modules/dashboard/interfaces/notification.interface';
import { NotificationService } from 'src/app/shared/services';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-dashboard-topbar',
  templateUrl: './dashboard-topbar.component.html',
  styleUrls: ['./dashboard-topbar.component.scss']
})
export class DashboardTopbarComponent implements OnInit{

  private authService = inject( AuthService );
  private notificationService = inject( NotificationService );
  private themeService = inject( ThemeService );

  public user = computed(() => this.authService.currentUser() );
  public theme = computed(() => this.themeService.preferredTheme());

  public actions: MenuItem[] | null;

  public role:string;

  public notifications:LuxNotification[] = [];
  constructor(private auth:AuthService, private router:Router) {
  }

  ngOnInit(): void {
    if (this.user()?.role === 'admin') {
      this.actions = [
        {
          icon: 'ri-scales-line',
          routerLink: ['./operations/new/from_sketch'],
          tooltipOptions: {
            tooltipLabel: 'Nueva operación'
        },
        },
        {
          icon: 'ri-folder-user-line',
          routerLink: ['./employees/new-employee'],
          tooltipOptions: {
            tooltipLabel: 'Añadir empleado'
        },
        },
        {
          icon: 'ri-alarm-warning-fill',
          routerLink: ['./opportunities'],
          tooltipOptions: {
            tooltipLabel: 'Gestionar oportunidades'
        },
        },
      ]
    } else {
      this.actions = [
        {
          icon: 'ri-scales-line',
          routerLink: ['./operations/new/from_sketch']
        },
      ]
    }

    this.notificationService.retrieveNotifications(this.user()!.uid).subscribe(
      res => {
        this.notifications =  res;
      }
    );
    switch (this.user()?.role) {
      case 'admin':
        this.role = 'Administrador'
        break;
      case 'manager':
        this.role = 'Manager'
        break;
      case 'user':
        this.role = 'Empleado'
        break;

    }
  }
  
  changeTheme(theme:'dark' | 'light') {
    const currentUrl = window.location.href;
    const fragments = currentUrl.split('/');
    const location = fragments[fragments.length - 1];
    this.themeService.switchTheme(theme);
    if (location === 'overview') window.location.reload();
  }

  public sidebarVisible:boolean = false;
  public menu:LuxorMenuItem[] = adminMenu;

  closeMenu():void {
    this.sidebarVisible = false;
  }

  signout() {
    this.auth.logout();
    this.router.navigate(['/auth']);
  }

  async markNotificationsAsRead() {
    const notificationsDB:LuxNotification[] = this.notifications;
    this.notifications = [];
    await this.notificationService.markNotificationAsRead(notificationsDB);
  }



}



