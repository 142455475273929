import { Injectable, inject } from '@angular/core';
import { Firestore, collection, doc, onSnapshot, query, setDoc, where } from '@angular/fire/firestore';
import { updateDoc } from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { Observable, of } from 'rxjs';
import { LuxNotification } from 'src/app/modules/dashboard/interfaces/notification.interface';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private db = inject(Firestore);

  retrieveNotifications(destination:string):Observable<LuxNotification[]> {
    let notifications:LuxNotification[] = [];
    const q = query(collection(this.db, 'notifications'), where('destination', '==', destination));
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const n = doc.data() as LuxNotification;
        if (!n.isAttended && !notifications.some(d => d.uid === n.uid )) {
            notifications.push(n);
        }
      });
    });
    return of(notifications);
  }

  async sendNotification(destination:string, content:string, type: 'contract' | 'cta' | 'action' | 'auth' | 'code') {
    const uid = nanoid(10);
    const notification:LuxNotification = {
      uid, 
      type,
      content,
      destination, 
      isAttended: false,
      creationDate: Date.now(),
    }
    try {
      await setDoc(doc(this.db, 'notifications', uid), notification);
    } catch (error) {
      console.log(error);
    }
  }

  async markNotificationAsRead(notifications:LuxNotification[]) {
    for (let i = 0; i < notifications.length; i++) { 
      const not = notifications[i];
      const ref = doc(this.db, "notifications", not.uid);
      await updateDoc(ref, {isAttended: true});
    }
  }

}
