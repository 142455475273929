import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { APIResponse, Spot, Timeserie } from '../interfaces/metals.dev.api.resp';
import { Observable, catchError, map, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetalsApiService {

  private http:HttpClient = inject(HttpClient);

  private _currentRequest = signal<APIResponse|null>(null);

  private readonly _apiKey = 'U0IEVLSHKXEDJSFGNCRQ486FGNCRQ';
  private readonly _spotUrl = 'https://api.metals.dev/v1/metal/spot';
  private readonly _seriesUrl = 'https://api.metals.dev/v1/timeseries';

  constructor() {
    this.requestMetals().subscribe();
  }

  public currentRequest = computed(() => this._currentRequest());

  requestMetals(): Observable<void | null> { 
      const hasResponse = localStorage.getItem('goldStock');
      let expiredRequest:boolean = false;
      if (hasResponse) {
        const response:APIResponse = JSON.parse(localStorage.getItem('goldStock')!) as APIResponse;
        const now = new Date().getTime();
        const expiration = new Date(response.exp!).getTime();
        if (now > expiration) {
          expiredRequest = true;
        } else {
          this.setResponse(response);
        }
      }
      if (expiredRequest || !hasResponse) {
        return this.http.get<APIResponse>('https://api.metals.dev/v1/latest?api_key=U0IEVLSHKXEDJSFGNCRQ486FGNCRQ&currency=EUR&unit=g')
        .pipe(
          map(res => {
            const expiration = new Date(Date.now() + 2 * (60 * 60 * 1000) );
            res.exp = expiration;
            this.setResponse(res);
          }),
          catchError( err => throwError( () => {console.log(err)} ))
        );
      } else {
        return of(null);
      }
  }

  private setResponse(res:APIResponse): boolean {
    this._currentRequest.set(res);
    localStorage.setItem('goldStock', JSON.stringify(res));
    return true;
  }

  requestSpot():Observable<Spot> {
    const endPoint:string = this._spotUrl + '?api_key=' + this._apiKey + '&metal=gold&currency=EUR';
    return this.http.get<Spot>(endPoint);
  }

  requestTimeSeries(firstDate:string, lastDate:string):Observable<Timeserie> {
    const endPoint:string = this._seriesUrl + '?api_key=' + this._apiKey + '&start_date=' + lastDate + '&end_date=' + firstDate;
    return this.http.get<Timeserie>(endPoint);
  }

}


