<div class="layout-topbar">
  <div>
    <a routerLink="/app">
      <img [src]="theme() === 'light' ?  'assets/img/logo_main.svg' : 'assets/img/logo_wh.svg'" alt="logo " width="120" height="auto" />
    </a>
  </div>
  <div class="ml-auto md:flex hidden">
    <p-speedDial
      [model]="actions"
      direction="down-left"
      [radius]="80" 
      type="quarter-circle"
    ></p-speedDial>
  </div>
  <div class="md:ml-2 ml-auto md:flex">
    <button pButton class="p-button-text" (click)="not.toggle($event)" *ngIf="notifications.length > 0">
      <i class="ri-notification-3-line text-xl" pBadge [value]="notifications.length.toString()"></i>
    </button>
    <button pButton class="p-button-text" *ngIf="notifications.length === 0">
      <i class="ri-notification-3-line text-xl" pBadge [value]="notifications.length.toString()"></i>
    </button>
  </div>
  <p-overlayPanel #not (onHide)="markNotificationsAsRead()">
    <div class="flex flex-column">
      <div *ngFor="let not of notifications">
        <div class="flex flex-row">
          <div class="col-fixed" style="width: 30px;">
            <i [ngClass]="{'ri-edit-2-line' : not.type === 'contract', 'ri-crosshair-2-line' : not.type === 'cta', 'ri-error-warning-line' : not.type === 'action'}"></i>
          </div>
          <div class="col">{{not.content}}</div>
        </div>
        <p-divider></p-divider>
      </div>
    </div>
  </p-overlayPanel>
  <div class="md:mr-2 md:flex">
    <button pButton class="p-button-text" (click)="changeTheme('dark')" *ngIf="theme() === 'light'">
      <i class="ri-moon-fill text-xl"></i>
    </button>
    <button pButton class="p-button-text" (click)="changeTheme('light')" *ngIf="theme() === 'dark'">
      <i class="ri-sun-line text-xl"></i>
    </button>
  </div>
  <div class="layout-topbar-menu align-items-center ml-2 lg:flex md:ml-0">
    <div class="cursor-pointer ml-1 hidden md:inline" (click)="op.toggle($event)">
      <span class="text-sm text-600"
        >{{ user()?.name! + ' ' + user()?.lastname }}<i class="ri-arrow-drop-down-line mr-2"></i
      ></span>
      <p-avatar
        [label]="(user()?.name)![0]"
        shape="circle"
      ></p-avatar>
    </div>
    <div>
      <p-overlayPanel #op>
        <div class="flex flex-column">
          <span>Conectado como: {{this.role}}</span>
          <p-button
            styleClass="p-button-danger p-button-text mt-2"
            (onClick)="signout()"
            >Cerrar sesión</p-button
          >
        </div>
      </p-overlayPanel>
      <p-button
        (click)="sidebarVisible = true"
        [rounded]="true"
        [outlined]="true"
        icon="ri-apps-2-line"
        styleClass="lg:hidden text-2xl ml-3"
      ></p-button>
    </div>
  </div>
</div>
<p-sidebar [(visible)]="sidebarVisible" position="right">
  <div class="flex flex-column justify-content-between h-full">
    <div>
      <ul class="pl-0">
        <li
          (click)="closeMenu()"
          class="cursor-pointer list-none sidebar-menu-item"
          *ngFor="let item of menu"
          [routerLink]="[item.route]"
          [routerLinkActive]="['active']"
        >
          <i class="{{ item.icon }} mr-2"></i> <span>{{ item.label }}</span>
        </li>
      </ul>
    </div>
    <div>
      <span>Conectado como: {{this.role}}</span>
      <p-button
        styleClass="p-button-danger p-button-text mt-2"
        (onClick)="signout()"
        >Cerrar sesión</p-button
      >
    </div>
  </div>
</p-sidebar>
