import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class EmailService {
    
  private readonly apiKey = environment.emailToken;
  private readonly emailUrl =  environment.emailApiUrl;

  private readonly http = inject(HttpClient);

  requestSignature(name:string, url:string, email:string) {
    const endpoint = `${this.emailUrl}/contract/request-signature`;

    // Crear los datos en formato x-www-form-urlencoded
    const body = new URLSearchParams();
    body.set('name', name);
    body.set('url', url);
    body.set('email', email);
    body.set('apiKey', this.apiKey);

    // Establecer los encabezados
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Enviar la petición POST
    return this.http.post(endpoint, body.toString(), { headers });

  }

  sendEmailConfirmation(name:string, url:string, email:string) {
    const endpoint = `${this.emailUrl}/contract/send-contract`;
    const body = {
      name,
      url,
      email, 
      'apiKey' : this.apiKey
    }

    // Establecer los encabezados
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Enviar la petición POST
    return this.http.post(endpoint, body, { headers } );

  }

    
} 