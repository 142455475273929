import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import { ThemeService } from './shared/services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

  constructor(private primengConfig: PrimeNGConfig, private themeService:ThemeService) {}

    ngOnInit() {
      console.log(environment.production); // Logs false for development environment

      this.themeService.initConfig();
      this.primengConfig.ripple = true;
      this.primengConfig.zIndex = {
        modal: 999,    // dialog, sidebar
        overlay: 998,  // dropdown, overlaypanel
        menu: 998,     // overlay menus
        tooltip: 1100   // tooltip
    };
    this.primengConfig.setTranslation({
      accept: 'Aceptar',
      reject: 'Cancelar',
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sept','Oct','Nov','Dic'],
      dayNames:['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      weekHeader: 'Sm',
      dateFormat: 'dd/mm/yy',
      firstDayOfWeek: 1,
  });
    }
}
