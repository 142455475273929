<header>
    <p-menubar [ngClass]="{'init' : init}">
        <ng-template pTemplate="start">
            <a href="/">
                <img src="https://firebasestorage.googleapis.com/v0/b/luxor-66cbe.appspot.com/o/logo.webp?alt=media&token=bb89dd30-8867-45d1-995b-e6932f3d4d6a" height="65" class="mr-2" alt="logo luxoro" loading="eager" />
            </a>
        </ng-template>
        <ng-template pTemplate="end">
            <p-button label="Tasación online" [routerLink]="['/']" fragment="contact" size="small"></p-button>
        </ng-template>
    </p-menubar>
</header>
