import { Component, OnInit, computed, inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-dashboard.layout',
  templateUrl: './dashboard.layout.component.html',
  styleUrls: ['./dashboard.layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit{

  private authService = inject( AuthService );

  public user = computed(() => this.authService.currentUser() );

  ngOnInit(): void {
    if (this.user()?.role === 'admin') {
      this.actions = [
        {
          icon: 'ri-scales-line',
          routerLink: ['./operations/new/from_sketch'],
          tooltipOptions: {
            tooltipLabel: 'Nueva operación'
        },
        },
        {
          icon: 'ri-folder-user-line',
          routerLink: ['./employees/new-employee'],
          tooltipOptions: {
            tooltipLabel: 'Añadir empleado'
        },
        },
        {
          icon: 'ri-alarm-warning-fill',
          routerLink: ['./opportunities'],
          tooltipOptions: {
            tooltipLabel: 'Gestionar oportunidades'
        },
        },
      ]
    } else {
      this.actions = [
        {
          icon: 'ri-scales-line',
          routerLink: ['./operations/new/from_sketch']
        },
      ]
    }
  }

  public actions: MenuItem[] | null;




}