import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, computed, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    public preferredTheme = computed(() => this._preferredTheme());
    private _preferredTheme = signal<'light' | 'dark'>('light');

    setupAppTheme() {
        const storedTheme = localStorage.getItem('theme') as 'light' | 'dark';
        const theme = storedTheme || 'light';
        this.switchTheme(theme);
    }

    getCurrentTheme(): 'light' | 'dark' {
        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        return themeLink?.href.includes('light') ? 'light' : 'dark';
    }

    switchTheme(theme: 'light' | 'dark') {
        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        if (themeLink) {
            localStorage.setItem('theme', theme);
            this._preferredTheme.set(theme);
            themeLink.href = `${theme}.css`;
        }
    }

    initConfig() {
        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        if (themeLink) {
            themeLink.href = 'light.css';
        }
    }
}
