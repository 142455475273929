import { Injectable, inject } from '@angular/core';
import { Attachment, Contract, Desglose, LuxorUser, Operation } from '../interfaces';
import { Firestore, arrayRemove, collection, collectionGroup, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { Storage, getDownloadURL, ref, uploadBytesResumable } from '@angular/fire/storage';
import { Store } from '../interfaces/store.interface';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  private db = inject(Firestore);
  private storage = inject(Storage);
  
  async postOperation(operation:Operation):Promise<boolean> { 
    try {
      if (!operation.isDeleted) operation.isDeleted = false;
      await setDoc(doc(this.db, 'operations', operation.uid), operation);
      return true;
    } catch (error) {
      return false;
    }
  }

  async updateOperation(operation:Operation):Promise<boolean> { 
    try {
      const opRef = doc(this.db, "operations", operation.uid);
      await updateDoc(opRef, { ...operation
      });
      return true;
    } catch (error) {
      console.log(`Error actualizando contrato: ${error}`);
      return false;
    }
  }

  async deleteOperation(operation:Operation):Promise<boolean> { 
    try {
      const opRef = doc(this.db, "operations", operation.uid);

      await updateDoc(opRef, { isDeleted: true
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  retrieveOperations():Observable<Operation[]> {
    const operations:Operation[] = [];
    const q = query(collection(this.db, "operations"));
    try {
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            operations.push(doc.data() as Operation);
        });
      });
    } catch (error) {
      console.log(error);
    }
    return of(operations);
  }

  async revertOperation(opId:string) {
    // Cuando falla la creación y hay que borrar la op
    const docRef = doc(this.db, 'operations', opId);
    const snap = await getDoc(docRef);
    const op:Operation = snap.data() as Operation;
    const clientId = op.seller!.uid;
    await this.revertClientOperation(clientId, opId);
    await deleteDoc(docRef);
    
  }

  async revertClientOperation(clientId:string, opId:string) {
    // Manejar el borrado en las operaciones del cliente
    const docRef = doc(this.db, 'clients', clientId);
    return await updateDoc(docRef, {
      operations: arrayRemove(opId)
    })
  }

  async retrieveEmployeesIds(manager:LuxorUser):Promise<string[]> { 
    const managerEmployees:string[] =  [];
    const docRef = doc(this.db, "stores", manager.current_store);
    const docSnap = await getDoc(docRef);
    const store:Store = docSnap.data() as Store;
    for (let employee of store.employees!) {
      managerEmployees.push(employee);
    }
    return managerEmployees;
  }

  async retreiveManagerOpertaions(manager:LuxorUser):Promise<Operation[]> { 
    const resultOperations:Operation[] = [];
    const managerEmployees = await this.retrieveEmployeesIds(manager);
    const mainQuery = query(collection(this.db, "operations"), where("employee", "==" , manager.uid), where("isDeleted", "!=" , true));
    const querySnapshot = await getDocs(mainQuery);
    querySnapshot.forEach((doc) => {
      const op:Operation = doc.data() as Operation;
      resultOperations.push(op);
    });
    const secondaryQuery = query(collection(this.db, "operations"), where("employee", "in" , managerEmployees));
    const secondarySnapshot = await getDocs(secondaryQuery);
    secondarySnapshot.forEach((doc) => {
      const op:Operation = doc.data() as Operation;
      resultOperations.push(op);
    });
    return resultOperations;

  }

  async retrieveEmployeeOperations(employee:LuxorUser):Promise<Operation[]> {
    const resultOperations:Operation[] = [];
    const mainQuery = query(collection(this.db, "operations"), where("isDeleted", "!=" , true), where("store", "==" , employee.current_store));
    const querySnapshot = await getDocs(mainQuery);
    querySnapshot.forEach((doc) => {
      const op:Operation = doc.data() as Operation;
      resultOperations.push(op);
    });
    return resultOperations;
  }


  async getOps():Promise<Operation[]> {
    const ops:Operation[] = [];
    const q = query(collection(this.db, "operations"), where("isDeleted", "!=" , true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const op:Operation =  doc.data() as Operation;
      ops.push(op);
    });
    return ops;
  }

  async getOpsNumber():Promise<number> {
    const q = query(collection(this.db, "operations"));
    const querySnapshot = await getDocs(q);
    const no = querySnapshot.docs.length;
    return no;
  }

  async uploadContract(contract:File, operation:Operation):Promise<Attachment> {
    let downloadUrl:string = '';
    const filePath = '/contracts/' + operation.uid;
    const fileRef = ref(this.storage, filePath);
    await uploadBytesResumable(fileRef, contract);
    await getDownloadURL(fileRef).then(url => downloadUrl = url);
    const contResult:Attachment = {
      name : contract.name ? contract.name : `contato_${operation.uid}`,
      type :  contract.type,
      size: contract.size,
      downloadUrl : downloadUrl
    }
    return contResult;
  }

  async detailDesgloseOperation(opId:string, desglose:Desglose):Promise<boolean> {
    const ref = doc(this.db, "operations", opId);
    try {
      const snap = await getDoc(ref);
      if (!snap.exists()) return false;
      const op:Operation = snap.data() as Operation;
      let status = 0;
      if (op.contract) {
        status = 3;
      } else {
        status = 1;
      }
      await updateDoc(ref, {
        desglose,
        status
      })
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async postDesglosePendingCode(opId:string, desglose:Desglose):Promise<boolean> {
    const ref = doc(this.db, "operations", opId);
    try {
      const snap = await getDoc(ref);
      if (!snap.exists()) return false;
      
      await updateDoc(ref, {
        desglose,
      })
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async addCodeToDesglose(opId:string, code:string, unbalaceReason:string) {
    const ref = doc(this.db, "operations", opId);
    try {
      const snap = await getDoc(ref);
      if (!snap.exists()) return false;
      const op:Operation = snap.data() as Operation;
      let status = 0;
      if (op.contract) {
        status = 3;
      } else {
        status = 1;
      }
      await updateDoc(ref, {
        status,
        "desglose.authCode": code,
        "desglose.unbalanceReason": unbalaceReason
      })
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }


  async retrieveCurrentYearOps():Promise<Operation[]> {
    const yearEpoch = new Date(new Date().getFullYear(), 0, 1);
    const result:Operation[] = [];
    const q = query(collection(this.db, "operations"), 
      where('status', '!=' , 0),
      where('closedDate', '>=' , yearEpoch.getTime())
    );
    try {
      const querySnapshot = await getDocs(q);
    querySnapshot.forEach(doc => {
      const op:Operation = doc.data() as Operation;
      result.push(op);
    });
    } catch (error) {
      console.log(error)
    }
    return result; 
  }

  async signOperation(operation:Operation, signature:string, contract:Attachment):Promise<boolean> {
    const now:number = Date.now();
    const opRef = doc(this.db, "operations", operation.uid);
    const newContract:Contract = {
      pdf: contract,
      signature: signature, 
      signatureDate: now,
    }

    try {
      await updateDoc(opRef, {
        closedDate: now,
        status: !operation.desglose ? 2 : 3,
        contract: newContract
      });
      return true;
    } catch (error) {
      return false;
    }
  }


  async getOperationById(id:string):Promise<Operation> {
    const ref = doc(this.db, "operations", id);
    const docSanp = await getDoc(ref);
    return docSanp.data() as Operation;
  }

  async retrievePoliceOperations(firstDate:number, lastDate:number, store:string):Promise<Operation[]> {
    const operations:Operation[] = [];
    const museums = query(collectionGroup(this.db, 'operations'), 
      where('closedDate', '<=', lastDate), 
      where('closedDate', '>=', firstDate),
      where('store', "==" , store),
      where('status', '==' , 3)
    );
      
    try {
      const querySnapshot = await getDocs(museums);
        querySnapshot.forEach((doc) => {
        const o:Operation = doc.data() as Operation;
        operations.push(o);
    });
    } catch (error) {
      console.log(error);
    }
    return operations;

  }

  async retrieveOperationsFromArray(ops:string[]):Promise<Operation[]> { 
    const result:Operation[] = [];
    for (const opId of ops) {
      const operation = await this.getOperationById(opId);
      if (operation) {  
        if(operation.uid) {
          result.push(operation);
        } 
      }
      }  
    return result;
  }  


}
