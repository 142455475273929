export const environment = {
production: false,
baseUrl: 'https://test.luxoro.es',
  firebase: {
    apiKey: 'AIzaSyAEBpnJ4kP1FinE-R1hYQUdMMNy8hNawYU',
    authDomain: 'luxoro-formacion.firebaseapp.com',
    projectId: 'luxoro-formacion',
    storageBucket: 'luxoro-formacion.appspot.com',
    messagingSenderId: '646944631374',
    appId: '1:646944631374:web:3a31e2ba4d562d26b3311f',
    measurementId: 'G-HZ0X65VK93',
  },
  employees: {
    apiKey: 'AIzaSyAEBpnJ4kP1FinE-R1hYQUdMMNy8hNawYU',
    authDomain: 'luxoro-formacion.firebaseapp.com',
    projectId: 'luxoro-formacion',
    storageBucket: 'luxoro-formacion.appspot.com',
    messagingSenderId: '646944631374',
    appId: '1:646944631374:web:7c97a7878465b75fb3311f',
    measurementId: 'G-PVMJNC8QM7',
  },
  apiGoogle: {
    maps: 'AIzaSyCVyjO-cVe3gKb_8FWrszGsX8UkNrpZWO8',
    recaptcha: '6LdoWlopAAAAALD9iUZMaml6DBhnrfWTKdBDf782'
  },
  emailApiUrl : 'https://apiluxoro.web.app/api/v1',
  emailToken: 'ly9lE160LYUeouAAhQKIjZV4WRfd',
  contractApiUrl : 'https://luxoro-contract-api-dev-1090793729375.europe-west3.run.app/report-service/contract/',
  contractToken: 'IH7PhxSa0qLXyWI15wJ1UVyDOudo'
};
