<ng-container>
    <div class="layout-wrapper">
        <app-dashboard-topbar />
        <div class="layout-sidebar">
            <app-dashboard-sidebar/>
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="layout-mask"></div>
    </div>
</ng-container>





