import { Component, computed, inject } from '@angular/core';
import {  Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styles: [
  ]
})
export class AppLoaderComponent {

  private authService = inject( AuthService );
  private router = inject( Router );
  private themeService = inject( ThemeService );

  constructor() {
    this.themeService.setupAppTheme();
  }


  public finishedAuthCheck = computed<boolean>( () => {
    if ( this.authService.currentUser() === null ) {
      return false;
    }
    if ( this.authService.currentUser()?.isDeleted) {
      this.router.navigate( [ 'auth/no-access' ] );
      return false;
    }
    if ( this.authService.currentUser()?.firstSession) {
      this.router.navigate( [ 'auth/alta-empleado' ] );
      return false;
    } else {
      return true;
    }
  });

}
