import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [
  ]
})
export class FooterComponent implements OnInit{
  ngOnInit(): void {
    this.displayedYear = this.currentYear.toString();
  }

  public currentYear:number = new Date().getFullYear();
  public displayedYear:string;

  navigateByUrl(url:string):void { 
    window.open(url);
  }
}
