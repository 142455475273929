import { Component, OnInit, computed, inject } from '@angular/core';
import { AuthService } from 'src/app/shared/services';
import { adminMenu, employeeMenu, managerMenu } from 'src/environments/variables';

interface MenuItem {
  label:string;
  icon?:string;
  route:string;
}

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: [
    './dashboard-sidebar.component.scss'
  ]
})
export class DashboardSidebarComponent implements OnInit {

  private authService = inject(AuthService)

  public user = computed(() => this.authService.currentUser());

  public homeItems:MenuItem[];

  ngOnInit(): void {

    switch (this.user()?.role) {
      case 'admin':
        this.homeItems = adminMenu;
        break;
      case 'manager':
        this.homeItems = managerMenu;
        break;

      case 'user' : 
      this.homeItems = employeeMenu;
        break;
    }

  }

}
