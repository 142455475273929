import { Injectable, inject } from '@angular/core';
import { Firestore, arrayUnion, collection, doc, getDoc, getDocs, updateDoc, writeBatch } from '@angular/fire/firestore';
import { Store } from '../interfaces/store.interface';
import { LuxorUser } from '../interfaces';
import { CashRegister } from 'src/app/modules/dashboard/interfaces/cash-register.interface';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private db = inject(Firestore);

  async retrieveStores():Promise<Store[]> {
    let stores:Store[] = [];
    const querySnapshot = await getDocs(collection(this.db, "stores"));
    querySnapshot.forEach((doc) => {
      stores.push(doc.data() as Store)
    });
    return stores;
    }

  async getStoreEmployees(uids:string[]):Promise<LuxorUser[]> {
    const employees:LuxorUser[] = [];
    const querySnapshot = await getDocs(collection(this.db, "users"));
    querySnapshot.forEach((doc) => {
      const employee = doc.data() as LuxorUser;
      if (uids.includes(employee.uid)) { 
        employees.push(employee);
      }
    });
    return employees;
  }

  async updateStoreEmployees(uid:string, employee:string):Promise<boolean> { 
    const storeRef = doc(this.db, "stores", uid);
    try {
      await updateDoc(storeRef, {
        employees: arrayUnion(employee)
    });
           
    return true;
    } catch (error) {
      return false;
    }
  }

  async getStoreById(id:string):Promise<Store> {
    const storeRef = doc(this.db, "stores", id);
    const snapshot =  await getDoc(storeRef);
    return snapshot.data() as Store;
  }

  async retrieveStoreManager(id:string):Promise<LuxorUser | undefined> {
    let manager:LuxorUser | undefined;
    const storeRef = doc(this.db, "stores", id);
    const snapshot =  await getDoc(storeRef);
    const store:Store =  snapshot.data() as Store;
    if(!store.employees) return undefined;
    for (let id of store.employees!) {
      const employeeRef = doc(this.db, "users", id);
      const snapshot =  await getDoc(employeeRef);
      const employee:LuxorUser = snapshot.data() as LuxorUser;
      if (employee) {
        if (employee.role === 'manager') {
          manager =  employee;
        }
        break;
      } else {
        manager = undefined;
      }
    }
    return manager;
  }

async createNewStore(store:Store, cashRegister:CashRegister):Promise<boolean> {

  const batch = writeBatch(this.db);

  const storeRef = doc(this.db, "stores", store.uid);
  batch.set(storeRef, store);
  const cashRef = doc(this.db, "cash_register", cashRegister.uid);
  batch.set(cashRef, cashRegister);

  try {
    await batch.commit();
    return true;
  } catch (error ) {
    console.log(error);
    return false;
  }

}

}


