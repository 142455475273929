import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import  localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrimengModule } from './shared/primeng/primeng.module';
import { MenuComponent } from './layout/menu/menu.component';
import { ContentLayoutComponent } from './layout/content.layout/content.layout.component';
import { FullpageLayoutComponent } from './layout/fullpage.layout/fullpage.layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './layout/footer/footer.component';
import { DashboardLayoutComponent } from './layout/dashboard.layout/dashboard.layout.component';
import { DashboardSidebarComponent } from './layout/dashboard-sidebar/dashboard-sidebar.component';
import { DashboardTopbarComponent } from './layout/dashboard-topbar/dashboard-topbar.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideStorage,getStorage, StorageModule } from '@angular/fire/storage';  
import { HttpClientModule } from '@angular/common/http';
import { AppLoaderComponent } from './layout/app-loader/app-loader.component';
import { SignaturePadModule } from 'angular-signature-pad-v2';



registerLocaleData(localeES);

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ContentLayoutComponent,
    FullpageLayoutComponent,
    FooterComponent,
    DashboardLayoutComponent,
    DashboardSidebarComponent,
    DashboardTopbarComponent,
    AppLoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PrimengModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SignaturePadModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    StorageModule,                                                                                                                                                                                                                                                                                                  
  ],
  providers: [
    {
      provide: LOCALE_ID, useValue: 'es',
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}
