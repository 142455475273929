import { Component } from '@angular/core';

@Component({
  selector: 'app-fullpage.layout',
  templateUrl: './fullpage.layout.component.html',
  styleUrls: ['./fullpage.layout.component.scss']
})
export class FullpageLayoutComponent {

}
