import { ProductType } from "src/app/shared/interfaces";
import { LuxorMenuItem } from "src/app/shared/interfaces/menuItem.interface";
  
  export const adminMenu:LuxorMenuItem[] = [
    {
      label: 'Inicio',
      route: './overview', 
      icon: 'ri-dashboard-2-fill'
    },
    {
      label: 'Negocio',
      route: './business', 
      icon: 'ri-donut-chart-fill'
    },
    {
      label: 'Operaciones',
      route: './operations', 
      icon: 'ri-service-fill'
    },
    {
      label: 'Oportunidades',
      route: './opportunities', 
      icon: 'ri-calendar-fill'
    },
    {
      label: 'Centros Luxoro',
      route: './stores', 
      icon: 'ri-store-2-fill'
    },
    {
      label: 'Registro de caja',
      route: './cash-register', 
      icon: 'ri-receipt-fill'
    },
    {
      label: 'Empleados',
      route: './employees', 
      icon: 'ri-folder-user-fill'
    },  
    {
      label: 'Clientes',
      route: './clients', 
      icon: 'ri-user-heart-fill'
    },
    {
      label: 'Autorizaciones',
      route: './authorizations', 
      icon: 'ri-key-fill'
    }
  ];

  export const managerMenu:LuxorMenuItem[] = [
    {
      label: 'Inicio',
      route: './overview', 
      icon: 'ri-dashboard-2-fill'
    },
    {
      label: 'Operaciones',
      route: './operations', 
      icon: 'ri-service-fill'
    },
    {
      label: 'Oportunidades',
      route: './opportunities', 
      icon: 'ri-calendar-fill'
    },
    {
      label: 'Centros Luxoro',
      route: './stores', 
      icon: 'ri-store-2-fill'
    },
    {
      label: 'Registro de caja',
      route: './cash-register', 
      icon: 'ri-receipt-fill'
    },
    {
      label: 'Empleados',
      route: './employees', 
      icon: 'ri-folder-user-fill'
    },  
    {
      label: 'Clientes',
      route: './clients', 
      icon: 'ri-user-heart-fill'
    },
    {
      label: 'Autorizaciones',
      route: './authorizations', 
      icon: 'ri-key-fill'
    }
  ];

  export const employeeMenu:LuxorMenuItem[] = [
    {
      label: 'Inicio',
      route: './overview', 
      icon: 'ri-dashboard-2-fill'
    },
    {
      label: 'Operaciones',
      route: './operations', 
      icon: 'ri-service-fill'
    },
    {
      label: 'Oportunidades',
      route: './opportunities', 
      icon: 'ri-calendar-fill'
    },
    {
      label: 'Centros Luxoro',
      route: './stores', 
      icon: 'ri-store-2-fill'
    },
    {
      label: 'Clientes',
      route: './clients', 
      icon: 'ri-user-heart-fill'
    },
    {
      label: 'Registro de caja',
      route: './cash-register', 
      icon: 'ri-receipt-fill'
    }
    
  ];


  export const productTypes:ProductType[] = [
    {
      "name" : "ORO24K",
      "desc" : "24K Oro",
      "pureza" : 999
    },
    {
      "name" : "ORO23K",
      "desc" : "23K Oro",
      "pureza" : 933.2
    },
    {
      "name" : "ORO22K",
      "desc" : "22K Oro",
      "pureza" : 892.2
    },
    {
      "name" : "ORO21K",
      "desc" : "21K Oro",
      "pureza" : 851.6
    },
    {
      "name" : "ORO20K",
      "desc" : "20K Oro",
      "pureza" : 811.1
    },
    {
      "name" : "ORO19K",
      "desc" : "19K Oro",
      "pureza" : 770.5
    },
    {
      "name" : "ORO18K",
      "desc" : "18K Oro",
      "pureza" : 730
    },
    {
      "name" : "ORO17K",
      "desc" : "17K Oro",
      "pureza" : 689.4
    },
    {
      "name" : "ORO16K",
      "desc" : "16K Oro",
      "pureza" : 648.9
    },
    {
      "name" : "ORO15K",
      "desc" : "15K Oro",
      "pureza" : 608.3
    },
    {
      "name" : "ORO14K",
      "desc" : "14K Oro",
      "pureza" : 567.8
    },
    {
      "name" : "ORO13K",
      "desc" : "13K Oro",
      "pureza" : 527.2
    },
    {
      "name" : "ORO12K",
      "desc" : "12K Oro",
      "pureza" : 486.6
    },
    {
      "name" : "ORO11K",
      "desc" : "11K Oro",
      "pureza" : 446.1
    },
    {
      "name" : "ORO10K",
      "desc" : "10K Oro",
      "pureza" : 405.5
    },
    {
      "name" : "ORO9K",
      "desc" : "9K Oro",
      "pureza" : 365
    },
    {
      "name" : "ORO8K",
      "desc" : "8K Oro",
      "pureza" : 324.4
    },
    {
      "name" : "PLATAF999",
      "desc" : "Plata Fina 999",
      "pureza": 999
    },
    {
      "name" : "PLATA950",
      "desc" : "Plata de Ley 950",
      "pureza": 950
    },
    {
      "name" : "PLATA925",
      "desc" : "Plata esterlina o de ley 925",
      "pureza": 925
    },
    {
      "name" : "PLATA900",
      "desc" : "Plata Segunda de Ley 900",
      "pureza": 900
    },
    {
      "name" : "Diamante",
      "desc" : "Diamantes sueltos",
    },
    {
      "name" : "Esmeralda",
      "desc" : "Esmeraldas sueltas",
    },
    {
      "name" : "Joyas",
      "desc" : "Joyas con piedras preciosas",
    },
    {
      "name" : "PLATINO",
      "desc" : "Platino",
    },
    {
      "name" : "RELOJES",
      "desc" : "Relojes S.M",
    },
    {
      "name" : "RELOJES M",
      "desc" : "Relojes SM Metal",
    },
    {
      "name" : "RUBÍS",
      "desc" : "Rubís sueltos",
    },
    {
      "name" : "ZAFIRO",
      "desc" : "Zafiro suelto",
    },
    {
      "name" : "NO ORO",
      "desc" : "Pieza de no oro",
    },
    {
      "name" : "NO PLATA",
      "desc" : "Pieza de no plata",
    },
  ]

  export const productTypesEmployee:ProductType[] = [
    {
      "name" : "ORO24K",
      "desc" : "24K Oro",
      "pureza" : 999
    },
    {
      "name" : "ORO22K",
      "desc" : "22K Oro",
      "pureza" : 892.2
    },
    {
      "name" : "ORO21K",
      "desc" : "21K Oro",
      "pureza" : 851.6
    },
    {
      "name" : "ORO18K",
      "desc" : "18K Oro",
      "pureza" : 730
    },
    {
      "name" : "ORO16K",
      "desc" : "16K Oro",
      "pureza" : 648.9
    },
    {
      "name" : "ORO14K",
      "desc" : "14K Oro",
      "pureza" : 567.8
    },
    {
      "name" : "ORO9K",
      "desc" : "9K Oro",
      "pureza" : 365
    },
    {
      "name" : "ORO8K",
      "desc" : "8K Oro",
      "pureza" : 324.4
    },
    {
      "name" : "PLATAF999",
      "desc" : "Plata Fina 999",
      "pureza": 999
    },
    {
      "name" : "PLATA950",
      "desc" : "Plata de Ley 950",
      "pureza": 950
    },
    {
      "name" : "PLATA925",
      "desc" : "Plata esterlina o de ley 925",
      "pureza": 925
    },
    {
      "name" : "PLATA900",
      "desc" : "Plata Segunda de Ley 900",
      "pureza": 900
    },
    {
      "name" : "Diamante",
      "desc" : "Diamantes sueltos",
    },
    {
      "name" : "Esmeralda",
      "desc" : "Esmeraldas sueltas",
    },
    {
      "name" : "Joyas",
      "desc" : "Joyas con piedras preciosas",
    },
    {
      "name" : "PLATINO",
      "desc" : "Platino",
    },
    {
      "name" : "RELOJES",
      "desc" : "Relojes S.M",
    },
    {
      "name" : "RELOJES M",
      "desc" : "Relojes SM Metal",
    },
    {
      "name" : "RUBÍS",
      "desc" : "Rubís sueltos",
    },
    {
      "name" : "ZAFIRO",
      "desc" : "Zafiro suelto",
    },
    {
      "name" : "NO ORO",
      "desc" : "Pieza de no oro",
    },
    {
      "name" : "NO PLATA",
      "desc" : "Pieza de no plata",
    },
  ]

export const mapStyle = [
  {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#e9e9e9"
          },
          {
              "lightness": 17
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f5f5f5"
          },
          {
              "lightness": 20
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 17
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 29
          },
          {
              "weight": 0.2
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 18
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f5f5f5"
          },
          {
              "lightness": 21
          }
      ]
  },
  {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#dedede"
          },
          {
              "lightness": 21
          }
      ]
  },
  {
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "visibility": "on"
          },
          {
              "color": "#ffffff"
          },
          {
              "lightness": 16
          }
      ]
  },
  {
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "saturation": 36
          },
          {
              "color": "#333333"
          },
          {
              "lightness": 40
          }
      ]
  },
  {
      "elementType": "labels.icon",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
          {
              "color": "#f2f2f2"
          },
          {
              "lightness": 19
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#fefefe"
          },
          {
              "lightness": 20
          }
      ]
  },
  {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#fefefe"
          },
          {
              "lightness": 17
          },
          {
              "weight": 1.2
          }
      ]
  }
];