<footer>
    <div class="container py-8">
        <p-divider></p-divider>
        <div class="col-12 container text-center">
            <img src="assets/img/logo_alt.png" width="120" loading="lazy" alt="logo footer">
            <p class="text-light">© {{displayedYear}} Luxoro. Todos los derechos reservados</p>
        </div>
        <div class="flex gap-3 justify-content-center">
            <div>
                <p-button icon="ri-instagram-line" [rounded]="true" [text]="true" (onClick)="navigateByUrl('https://www.instagram.com/luxoro_oro/?hl=es')"></p-button>
            </div>
            <div>
                <p-button icon="ri-mail-line" [rounded]="true" [text]="true" (onClick)="navigateByUrl('mailto:info@luxoro.com')"></p-button>
            </div>
            <div>
                <p-button icon="ri-phone-line" [rounded]="true" [text]="true" (onClick)="navigateByUrl('tel:+34661787967')"></p-button>
            </div>
        </div>
        <div class="flex flex-nowrap justify-content-center mt-3">
            <div>
                <a routerLink="/legal">Privacidad</a>
            </div>
            <div class="text-light">
                &nbsp;|&nbsp;
            </div>
            <div>
                <a routerLink="/legal/cookies">Cookies</a>
            </div>
            <div class="text-light">
                &nbsp;|&nbsp;
            </div>
            <div>
                <a routerLink="/legal/sorteos">Sorteos</a>
            </div>
        </div>
    </div>
</footer>